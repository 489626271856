import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
import '../progress/jha-progress.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaButtonElement = class extends PolymerElement {
  static get is() {
    return 'jha-button';
  }

  static get properties() {
    return {
      isLoading: {
        type: Boolean,
        value: false,
        reflectToAttribute: true,
      },
      disabled: {
        type: Boolean,
        value: false,
      },
      sync: {
        type: Boolean,
        value: false,
      },
      focused: {
        type: Boolean,
        reflectToAttribute: true,
        value: false,
      },
      type: String,
      ariaLabel: String,
    };
  }

  constructor() {
    super();
    /** @type {?Element} */
    this.form_ = null;
    this.boundClick_ = this.onClick_.bind(this);
    this.addEventListener('click', this.boundClick_);
    this.boundFormSubmit_ = this.onFormSubmit_.bind(this);
    this.boundOnFocus_ = this.onFocus_.bind(this);
    this.boundOnBlur_ = this.onBlur_.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    this.form_ = this.closest('form');

    if (this.form_ && this.type === 'submit') {
      this.form_.addEventListener('submit', this.boundFormSubmit_, false);
    }
    const button = this.shadowRoot.querySelector('button');
    button.addEventListener('focus', this.boundOnFocus_);
    button.addEventListener('blur', this.boundOnBlur_);
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.removeEventListener('click', this.boundClick_);
    if (this.form_ && this.type === 'submit') {
      this.form_.removeEventListener('submit', this.boundFormSubmit_, false);
    }
  }

  reset() {
    setTimeout(() => {
      this.disabled = false;
      this.isLoading = false;
    }, 0);
  }

  /**
   * @private
   * @param {!Event} event
   */
  onFocus_(event) {
    this.focused = true;
  }

  /**
   * @private
   * @param {!Event} event
   */
  onBlur_(event) {
    this.focused = false;
  }

  /**
   * @private
   * @param {!Event} event
   */
  onClick_(event) {
    if (this.disabled || this.isLoading) {
      event.stopPropagation();
      event.preventDefault();
      event.stopImmediatePropagation();
      return;
    }
    if (this.form_ && this.type === 'submit') {
      /*
      Perhaps because Chrome supports Shadow DOM, and Firefox uses Shady DOM, Chrome does not
      automatically fire a `submit` event when a <jha-button type="submit"> is clicked, but Firefox
      does. The following is to normalize the behavior by canceling any default behavior and dispatching
      our own event so the desired behavior works in all supported browsers.
      */
      event.preventDefault(); // prevent default submit action (Gecko)
      this.form_.dispatchEvent(new CustomEvent('submit', {
        composed: true,
        bubbles: true,
        cancelable: true,
      }));
    } else if (!this.sync) {
      this.isLoading = true;
    }
  }

  /**
   * @private
   * @param {!Event} evt
   */
  onFormSubmit_(evt) {
    evt.preventDefault();
    if (!this.disabled && !this.sync) {
      this.isLoading = true;
    }
  }

  static get template() {
    return html`
    <style>
      :host {
        display: inline-block;
        text-align: center;
        margin-bottom: 0;
        touch-action: manipulation;
        cursor: pointer;
      }

      button {
        -webkit-appearance: none;
        display: var(--jha-button-display, inline-flex);
        justify-content: var(--jha-button-justify-content, center);
        align-items: center;
        position: relative;
        text-align: var(--jha-button-text-align, center);
        vertical-align: middle;
        contain: layout;
        cursor: pointer;
        background-image: none;
        border: var(--jha-button-border, 1px solid transparent);
        white-space: var(--jha-button-white-space, nowrap);
        background-color: var(--jha-button-background, var(--jha-color-primary, #006ee4));
        text-transform: var(--jha-button-text-transform, none);
        color: var(--jha-button-text, var(--jha-text-white, #ffffff));
        padding: var(--jha-button-padding-vertical, 0px) var(--jha-button-padding-horizontal, 18px);
        font-size: var(--jha-button-text-size, 14px);
        font-family: inherit;
        font-weight: var(--jha-button-font-weight, 500);
        line-height: var(--jha-button-line-height, 34px);
        border-radius: var(--jha-button-border-radius, 8px);
        box-shadow: none;
        box-sizing: border-box;
        overflow: var(--jha-button-overflow, hidden);
        transition: background-color ease 0.2s;
        width: var(--jha-button-width, auto);
        height: var(--jha-button-height, auto);
      }

      button:focus:not(:focus-visible) {
        outline: 0;
      }

      button:hover,
      button:focus-visible {
        text-decoration: var(--jha-button-text-decoration-hover, none);
        background-color: var(--jha-button-background-hover, var(--jha-color-primary, #006ee4));
        border-color: var(--jha-button-border-color-hover, transparent);
      }

      button:active {
        background-color: var(--jha-button-background-active, var(--jha-color-primary, #006ee4));
      }

      button[disabled] {
        opacity: var(--jha-button-disabled-opacity, 0.3);
        cursor: default;
      }
      button[disabled]:hover,
      button[disabled]:focus-visible {
        background-color: var(--jha-button-background, var(--jha-color-primary, #006ee4));
        border-color: var(--jha-button-border-color, transparent);
        box-shadow: none;
      }

      :host ::slotted(.icon) {
        position: relative;
        top: -1px;
      }

      :host([default]) button {
        color: var(--jha-button-default-text, var(--jha-text-base, #707070));
        background-color: var(--jha-button-default-background, transparent);
        border-color: var(--jha-button-default-border, var(--jha-color-gray-light, #e6e6e6));
        font-size: var(--jha-button-default-font-size, 14px);
        transition: background-color ease 0.2s;
      }

      :host([default]) button:hover {
        background-color: var(--jha-button-default-background-hover, rgba(153, 153, 153, 0.12));
      }

      :host([default]) button:active {
        background-color: var(--jha-button-default-background-active, rgba(153, 153, 153, 0.4));
      }

      :host([outline]) button {
        color: var(--jha-button-outline-text, var(--jha-text-base, #707070));
        background-color: var(--jha-button-outline-background, transparent);
        border-color: var(--jha-button-outline-border, var(--jha-border-color, #e4e7ea));
        transition: background-color ease 0.2s;
      }

      :host([outline]) button:hover {
        background-color: var(--default-button-focused-color, var(--jha-button-outline-background-hover, rgba(153, 153, 153, 0.12)));
        color: var(--default-button-text-focused-color, var(--jha-button-outline-color-hover, var(--jha-color-primary)));
      }

      :host([outline]) button:active {
        background-color: var(--jha-button-outline-background-active, rgba(153, 153, 153, 0.4));
      }

      :host([outline-primary]) button {
        color: var(--jha-button-primary-background, var(--jha-color-primary, #006ee4));
        background-color: transparent;
        border-color: var(--jha-button-primary-background, var(--jha-color-primary, #006ee4));
      }

      :host([outline-primary]) button:hover {
        background-color: var(--jha-button-outline-primary-background-hover, rgba(52,177,224,.12));
      }

      :host([outline-primary]) button:active {
        color: var(--jha-button-text, var(--jha-text-white, #ffffff));
        background-color: var(--jha-button-primary-background, var(--jha-color-primary, #006ee4));
      }

      :host([outline-success]) button {
        color: var(--jha-button-success-background, var(--jha-color-success, #4caf50));
        background-color: transparent;
        border-color: var(--jha-button-success-background, var(--jha-color-success, #4caf50));
      }

      :host([outline-success]) button:hover {
        background-color: var(--jha-button-outline-success-background-hover, rgba(70,181,74,.12));
      }

      :host([outline-success]) button:active {
        color: var(--jha-button-text, var(--jha-text-white));
        background-color: var(--jha-button-success-background, var(--jha-color-success, #4caf50));
      }

      :host([outline-danger]) button {
        color: var(--jha-button-danger-background, var(--jha-color-danger, #f44336));
        background-color: transparent;
        border-color: var(--jha-button-danger-background, var(--jha-color-danger, #f44336));
      }

      :host([outline-danger]) button:hover {
        background-color: var(--jha-button-outline-danger-background-hover, rgba(249,62,49,.12));
      }

      :host([outline-danger]) button:active {
        color: var(--jha-button-text, var(--jha-text-white));
        background-color: var(--jha-button-danger-background, var(--jha-color-danger, #f44336));
      }

      :host([icon]) button {
        background-color: var(--jha-button-icon-background, transparent);
        padding: var(--jha-button-icon-padding, 0);
        border-radius: var(--jha-button-icon-border-radius, 50%);
        line-height: var(--jha-button-icon-line-height, 0);
      }

      :host([icon]) button:hover:not([disabled]),
      :host([icon]) button:focus-visible {
        background-color: var(--jha-button-icon-background-hover, transparent);
        box-shadow: none;
      }

      :host([icon]) button a:hover {
        background-color: transparent;
      }

      :host([link]) button {
        color: var(--link-button-text-color, var(--jha-button-link-text, var(--jha-color-primary, #006ee4)));
        font-weight: var(--jha-button-link-font-weight, 400);
        background-color: transparent;
        padding: var(--jha-button-link-padding-vertical, 0) var(--jha-button-link-padding-horizontal, 18px);
        line-height: var(--jha-button-link-line-height, 34px);
        border-radius: var(--jha-button-link-border-radius, var(--jha-button-border-radius, 8px));
      }

      :host([link]) button:hover,
      :host([link]) button:focus-visible {
        box-shadow: none;
        color: var(--link-button-text-focused-color, var(--jha-button-link-text-hover, var(--jha-color-primary, #006ee4)));
        background-color: var(--jha-button-link-background-hover, rgba(0, 110, 228, .10));
        border-color: transparent;
      }

      :host([link][disabled]) button:hover,
      :host([link][disabled]) button:focus-visible,
      :host([link][disabled]) button:active {
        background-color: transparent;
      }

      :host([link]) button:active {
        background-color: var(--jha-button-link-background-active, rgba(0, 110, 228, .20));
      }

      :host([link][footer]) {
        margin-left: var(--jha-button-link-footer-margin-left, -18px);
      }

      button[is-loading] {
        overflow: visible;
        opacity: 1;
      }

      button[is-loading] jha-progress {
        display: inline-block;
      }

      :host([default]) jha-progress,
      :host([link]) jha-progress {
        border-color: var(--jha-button-link-text, var(--jha-color-primary, #006ee4));
      }

      button[is-loading] .slot-wrapper {
        opacity: 0;
      }

      .jha-progress-wrapper {
        position: absolute;
        display: flex;
        justify-content: center;
        left: 0;
        right: 0;
      }

      button .slot-wrapper,
      button jha-progress {
        transition: opacity .2s cubic-bezier(.1, .5, .1, 1);
      }

      button .slot-wrapper {
        display: var(--jha-button-slot-wrapper-display, inline-block);
      }

      jha-progress {
        pointer-events: none;
        position: relative;
        border-color: var(--jha-progress-color-button, var(--jha-text-white));
        display: none;
      }
      /* IE 11 specific style to fix placement of jha-progress when button is submitted */
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        jha-progress.jha-button {
          margin-top: -10px;
        }
      }
      :host([outline]) jha-progress {
        border-color: var(--jha-progress-color-button-outline, var(--jha-color-primary, #006ee4));
      }

      :host([primary]) button {
        background-color: var(--jha-button-primary-background, var(--jha-color-primary, #006ee4));
      }

      :host([neutral]) button {
        background-color: var(--jha-button-neutral-background, #dadcde);
        color: var(--jha-button-neutral-text, #6b757b);
      }

      :host([success]) button {
        background-color: var(--jha-button-success-background, var(--jha-color-success, #4caf50));
      }

      :host([danger]) button {
        background-color: var(--jha-button-danger-background, var(--jha-color-danger, #f44336));
      }

      :host([toggle]) button {
        color: var(--jha-button-toggle-text, var(--jha-color-neutral, #8d99a0));
        font-size: var(--jha-button-toggle-text-size, 13px);
        background-color: transparent;
        text-transform: var(--jha-button-toggle-text-transform, uppercase);
      }

      :host([toggle]) button:hover {
        color: var(--jha-button-toggle-text-hover, var(--jha-color-primary, #006ee4));
        background-color: transparent;
      }

      :host([x-small]) button {
        font-size: calc(var(--jha-button-text-size, 14px) - 2);
        line-height: var(--jha-button-line-height-xsmall, 14px);
      }

      :host([small]) button {
        font-size: calc(var(--jha-button-text-size, 14px) - 1);
        line-height: var(--jha-button-line-height-small, 28px);
      }

      :host([large]) button {
        line-height: var(--jha-button-line-height-large, 48px);
        padding-left: calc(var(--jha-button-padding-horizontal, 18px) * 1.5);
        padding-right: calc(var(--jha-button-padding-horizontal, 18px) * 1.5);
      }

      :host([wide]) button {
        padding-left: calc(var(--jha-button-padding-horizontal, 18px) * 1.5);
        padding-right: calc(var(--jha-button-padding-horizontal, 18px) * 1.5);
      }

      :host([block]) {
        display: block;
        margin-bottom: 5px;
      }

      :host([block]:last-of-type) {
        margin-bottom: 0;
      }

      :host([block]) button {
        width: var(--jha-button-block-width, 100%);
      }
      :host([flex]) .slot-wrapper {
        --jha-button-slot-wrapper-display: flex;
        flex-direction: var(--jha-button-flex-direction, row);
        align-items: var(--jha-button-flex-align-items, center);
        justify-content: var(--jha-button-flex-justify-content, flex-start);
        width: var(--jha-button-flex-width);
        height: var(--jha-button-flex-height);
        text-align: left;
      }
      :host([grid]) button {
        --jha-button-link-padding-horizontal: var(--jha-button-grid-padding-horizontal, 0);
        --jha-button-text-align: var(--jha-button-grid-text-align, left);
      }
      :host([grid]) .slot-wrapper {
        width: var(--jha-button-grid-width, 100%);
      }
      :host([grid]) .slot-wrapper slot {
        display: grid;
        grid-auto-flow: var(--jha-button-grid-auto-flow, column);
        grid-template-columns: var(--jha-button-grid-template-columns);
        gap: var(--jha-button-grid-gap, 24px);
      }
    </style>
    <!-- eslint-disable-next-line @banno/ux/valid-html -->
    <button type$="[[type]]" disabled$="[[disabled]]" is-loading$="[[isLoading]]" aria-label$="[[ariaLabel]]" tabindex="0">
      <span class="jha-progress-wrapper">
        <jha-progress></jha-progress>
      </span>
      <span class="slot-wrapper">
        <slot></slot>
      </span>
    </button>
  `;
  }
};

customElements.define(window.JhaButtonElement.is, window.JhaButtonElement);
export default window.JhaButtonElement;
